<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="info" @click="reset">重置</el-button>
					<!-- <el-button type="success" @click="goaddnew">新增</el-button> -->
				</div>
			</div>
			<div class="box_cont">
				<!-- <div class="btn_list">
					<el-button type="primary" @click="goaddnew">新增</el-button>
				</div> -->
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" @clickcharu="clickcharu" :showbm="true" :czwidth="210"
						 @getdata="gettable" @changswitch="setstatus">
						 <template slot="btns" slot-scope="{ scope }">
						 	<el-button @click="showedit(scope.row)" type="primary" size="mini">修改</el-button>
						 	<el-button @click="deleterow(scope.row)" type="danger" size="mini">删除</el-button>						 
						 </template>
					</MyTable>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchinput: '',
				tableHeader: [					
					{
						name: '标题',
						prop: 'name',
						width: '200'
					},
					{
						name: '内容',
						prop: 'content',
						width: ''
					},
					{
						name: '截止时间',
						prop: 'endtime',
						width: '180'
					},
					{
						name: '发布时间',
						prop: 'addtime',
						width: '180'
					}
				],
				tableData: [],

			}
		},
		mounted() {
			this.gettable()
		},
		activated() {
		    this.gettable(); // 每次进入页面时刷新数据
		},
		methods: {
			goaddnew(){
				this.$router.push('/system/add_zhengji')
			},
			setstatus(row){
				delete row.tzname
				delete row.image_fill
				this.$post({
					url:'/admin/banner/edit',
					params:row
				}).then((res)=>{
					this.$message.success('操作成功')
					this.gettable()
				})
			},
			addnew(){
				this.$router.push('/system/swiper_add')
			},
			reset(){
				this.searchinput=''
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			search(){
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable(){
				this.$post({
					url:'/api/proposazj/index',
					params:{
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						name:this.searchinput
					}
				}).then((res)=>{
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			clickcharu(e){
				console.log(e)
				this.$refs.myinvoiceadd.showkkd = true
			},
			handleSelectionChange(e) {
				console.log(e)
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.$post({
							url:'/api/proposazj/del',
							params:{
								ids:row.id
							}
						}).then((res)=>{
							this.$message.success('删除成功')
							this.gettable()
						})
					})
					.catch(_ => {});

			},
			showsetrole(row) {
				console.log(row)
				this.$refs.mymx.showmx = true
			},
			changswitch(row) {
				console.log(row)
			},
			showedit(row) {
				this.$router.push({path:'/system/edit_zhengji',query:{id:row.id}})
			},

		},
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		
	}

	::v-deep .el-table td.el-table__cell {
		
	}

	::v-deep .el-table {
		margin-top: 10px;
		
	}

	::v-deep .el-table__fixed {
		
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	
</style>